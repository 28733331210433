<template>
    <div class="mytabbar d-flex flex-row">
        <div style="margin-bottom: 2px; flex:1" @click="handleClick(index)" v-for="(item, index) in props.items"
            :key="`key_tab_${item.title}`" class=" flex-column d-flex align-center justify-center">
            <!-- <div v-show="modelValue == index" class="select_background"></div> -->
            <!-- other -->
            <div v-show="index != 2" style="min-height: 25px;min-width: 25px;height: 25px;">
                <img :style="{
                    'z-index': 1,
                    'height': modelValue != index ? '25px' : '0px',
                }" v-show="modelValue != index" :width="25" :max-height="25" :src="item.icon"></img>
                <img :class="{
                    animate__jello__Bar: modelValue == index,
                }" :style="{
                    'z-index': 1,
                    'height': modelValue == index ? '25px' : '0px',
                }" :width="25" :max-height="25" :src="item['select-icon']"></img>
            </div>
            <div v-show="index != 2" class="tabTitle" :style="{
                'z-index': 1,
                'color': modelValue == index ? '#FFFFFF' : '#CDCDE9',
            }">{{ item.title }}</div>
            <!-- other -->
            <div v-show="index == 2" style="
            height: 50px;width:50px;position: absolute;top:-20px;border-radius: 25px">
                <div :style="{
                    'scale': '0.9',
                    'z-index': 1,
                    'height': modelValue != index ? '50px' : '0px',
                }" class="no-invite" v-show="modelValue != index"></div>
                <div :style="{
                    'scale': '0.9',
                    'z-index': 1,
                    'height': modelValue == index ? '50px' : '0px',
                }" class="invite" v-show="modelValue == index"></div>

                <img :style="{
                    'z-index': 1,
                    'height': modelValue != index ? '23px' : '0px',
                }" v-show="modelValue != index" style="position: absolute;top: 13px;left: 12px;" :width="25"
                    :max-height="50" :src="item.icon"></img>
                <img :class="{
                    animate__jello__Bar: modelValue == index,
                }" :style="{
                    'z-index': 1,
                    'height': modelValue == index ? '23px' : '0px',
                }" style="position: absolute;top: 13px;left: 12px;" :width="25" :max-height="50"
                    :src="item['select-icon']"></img>

            </div>

            <div v-show="index == 2" class="tabTitle" :style="{
                'margin-bottom': '-26px',
                'z-index': 1,
                'color': modelValue == index ? '#FFFFFF' : '#CDCDE9',
            }">{{ item.title }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
const router = useRouter()
const route = useRoute()

const emit = defineEmits(['update:modelValue', 'change'])
const ishow = ref(true)

type TabItem = {
    "title": string,
    "icon": string,
    "select-icon": string,
    "userdata": any,
}

interface Props {
    "items"?: any,
    modelValue?: any
}

const props = withDefaults(defineProps<Props>(), {
    "items": [
        {
            "title": '',
            "icon": '/tabbar/home-0.png',
            "select-icon": '/tabbar/home-1.png'
        },
        {
            "title": '',
            "icon": '/tabbar/bonus-0.png',
            "select-icon": '/tabbar/bonus-1.png'
        },
        {
            "title": '',
            "icon": '/tabbar/invite-0.png',
            "select-icon": '/tabbar/invite-1.png'
        },
        {
            "title": '',
            "icon": '/tabbar/deposit-0.png',
            "select-icon": '/tabbar/deposit-1.png'
        },
        {
            "title": '',
            "icon": '/tabbar/person-0.png',
            "select-icon": '/tabbar/person-1.png'
        },

    ],
    modelValue: -1
})

props.items[0].title = "Início"
props.items[1].title = "Promoção"
props.items[2].title = "Afiliado"
props.items[3].title = "Depósito"
props.items[4].title = "Perfil"

const modelValue = ref(props.modelValue)
const preValue = ref(props.modelValue)

watch(() => props.modelValue, (val) => {
    preValue.value = modelValue.value
    modelValue.value = val
})

const handleClick = (va: any) => {
    preValue.value = modelValue.value
    modelValue.value = va
    emit('update:modelValue', va)
    emit('change', va)
}
if (route.name == 'index') {
    modelValue.value = 0

}
else if (route.name == 'ActivityCenter') {
    modelValue.value = 1

}
else if (route.name == 'Promotion') {
    modelValue.value = 2

}
else if (route.name == 'DepositPIX') {
    modelValue.value = 3

}
else if (route.name == 'UserInfo') {
    modelValue.value = 4

}
else if (route.name == 'betby' || route.name == 'BonusCenter') {
    modelValue.value = -1
}


router.beforeEach((to, from) => {
    if (to.name == 'index') {
        modelValue.value = 0

    }
    else if (to.name == 'category-id') {
        modelValue.value = -1

    }
    else if (to.name == 'ActivityCenter') {
        modelValue.value = 1

    }
    else if (to.name == 'Promotion') {
        modelValue.value = 2

    }
    else if (to.name == 'DepositPIX') {
        modelValue.value = 3

    }
    else if (to.name == 'UserInfo') {
        modelValue.value = 4

    }
    else if (to.name == 'betby' || to.name == 'BonusCenter') {
        modelValue.value = -1
    }

    return true
})

</script>
<style lang="scss" scoped>
.bg-surface {
    color: rgb(var(--v-theme-surface-variant)) !important;
}

.mytabbar {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    position: fixed;
    bottom: 15px;
    left: 5px;
    right: 5px;
    z-index: 100;
    height: 58px;
    border-bottom: 1px solid #2D2F4D;
    background: #262837CC;
    box-shadow: 0px -20px 30px 0px rgba(23, 25, 44, 0.50);
    //backdrop-filter: blur(5px);
}

.select_background {
    position: absolute;
    background: #4F40E3;
    width: 65px;
    height: 50px;
    border-radius: 15px;
    top: 4px;
}

.select_img {
    position: absolute;
}

.v-btn:hover>.v-btn__overlay {
    opacity: 0 !important;
}

.bk {
    width: 100%;
    position: absolute;
    left: 0px;
    right: 0px;
    background: red;
    height: 7px;
}

.tabTitle {
    font-family: Microsoft YaHei Bold !important;
    font-size: 13px;
    font-style: normal;
    font-weight: 700;
    line-height: 10px;
    color: rgb(var(--v-theme-surface-variant));
}

.no-invite {
    background-image: url('/tabbar/no-invite.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

.invite {
    background-image: url('/tabbar/invite.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
</style>