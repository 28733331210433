
       if (typeof window !== 'undefined') {
         function loadSvg() {
           var body = document.body;
           var svgDom = document.getElementById('__svg__icons__dom__');
           if(!svgDom) {
             svgDom = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
             svgDom.style.position = 'absolute';
             svgDom.style.width = '0';
             svgDom.style.height = '0';
             svgDom.id = '__svg__icons__dom__';
             svgDom.setAttribute('xmlns','http://www.w3.org/2000/svg');
             svgDom.setAttribute('xmlns:link','http://www.w3.org/1999/xlink');
           }
           svgDom.innerHTML = "<symbol  viewBox=\"0 0 20 22\" id=\"icon-Container\"><path d=\"M12.695 11.527 15.273 11l-2.578-.488a2.498 2.498 0 0 0-.254-.772c-.13-.254-.3-.478-.507-.674a2.635 2.635 0 0 0-1.934-.8c-.755 0-1.4.267-1.934.8a2.635 2.635 0 0 0-.8 1.934 2.7 2.7 0 0 0 .8 1.953c.534.534 1.179.8 1.934.8.755 0 1.4-.266 1.934-.8.208-.208.374-.433.498-.674.123-.24.211-.491.263-.752ZM20 11c0-1.38-.26-2.676-.781-3.887A10.067 10.067 0 0 0 17.08 3.94a10.277 10.277 0 0 0-3.193-2.158A9.731 9.731 0 0 0 10 1c-1.38 0-2.676.26-3.887.781A10.277 10.277 0 0 0 2.92 3.94 10.065 10.065 0 0 0 .78 7.113 9.732 9.732 0 0 0 0 11c0 1.38.26 2.676.781 3.887A10.006 10.006 0 0 0 2.92 18.08c.905.905 1.97 1.618 3.193 2.139C7.324 20.739 8.62 21 10 21s2.676-.26 3.887-.781a10.008 10.008 0 0 0 3.193-2.139 10.008 10.008 0 0 0 2.139-3.193C19.739 13.676 20 12.38 20 11ZM3.809 17.191l3.906-3.886a3.308 3.308 0 0 1-.527-.684 2.886 2.886 0 0 1-.313-.781l-5.332 1.426a10.507 10.507 0 0 1-.215-1.114 8.424 8.424 0 0 1 0-2.295c.052-.384.124-.758.215-1.123l5.332 1.426c.065-.26.17-.514.313-.762a3.31 3.31 0 0 1 .527-.683L3.809 4.809a8.57 8.57 0 0 1 1.308-1.075A9.055 9.055 0 0 1 7.158 2.72a8.43 8.43 0 0 1 .576-.176L9.16 7.875a3.042 3.042 0 0 1 1.68 0l1.426-5.332a8.432 8.432 0 0 1 1.132.39 9.05 9.05 0 0 1 1.485.801c.469.313.905.671 1.308 1.075l-3.886 3.906c.195.208.364.436.508.683.143.248.247.502.312.762l5.332-1.426c.091.365.163.736.215 1.114a8.422 8.422 0 0 1 0 2.304c-.052.378-.124.749-.215 1.114l-5.332-1.426c-.065.273-.166.53-.303.771a3.16 3.16 0 0 1-.517.674l3.886 3.906c-.403.404-.84.762-1.308 1.075a9.046 9.046 0 0 1-2.041 1.015c-.189.065-.381.124-.576.176l-1.426-5.332a3.042 3.042 0 0 1-1.68 0l-1.426 5.332a8.386 8.386 0 0 1-1.132-.39 9.05 9.05 0 0 1-1.485-.801A8.567 8.567 0 0 1 3.81 17.19Z\" /></symbol>";
           body.insertBefore(svgDom, body.lastChild);
         }
         if(document.readyState === 'loading') {
           document.addEventListener('DOMContentLoaded', loadSvg);
         } else {
           loadSvg()
         }
      }
        
export default {}