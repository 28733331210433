<script setup lang="ts">
import logo from '@/assets/components/appbar/logo.png'
import download from '@/assets/components/download/Rectangle.png'
import rewardImg from '@/assets/components/appbar/rewardImg.png'
import helpImg from '@/assets/components/appbar/help.png'
import giftImg from '@/assets/components/appbar/gift.png'
import moneyIcon from '@/assets/images/userinfo/BRL.png'
import { useBus } from '@/composables/xfun/bus'

const userstore = useUserStore()
const useractive = useActiveStore()
const refreshbalance = ref(false)
const showDetail = ref(false)
const bus = useBus()

const emit = defineEmits(['appIconclick', 'register', 'login', 'clickMenu'])
const handleIconClick = () => {
  emit('appIconclick')
}
const handleMenu = () => {
  emit('clickMenu')
}
const handleRegister = () => {
  bus.fireburyingpoint.emit("index_register")
  bus.fireburyingpoint.emit("email_register")
  emit('register')
}
const handleLogin = () => {
  emit('login')
}


let router = useRouter()
let testLabel = computed(() => {
  let path = router.currentRoute.value.path
  return path == '/Test' ? 'Home' : 'Test'
})

const handleTest = () => {
  let path = router.currentRoute.value.path
  if (path == '/Test') {
    router.push('/')
  } else {
    router.push('/Test')
  }
}
const goToBonus = () => {
  showDetail.value = false
  router.push('/BonusCenter')
}
const goToDesposit = () => {
  showDetail.value = false
  bus.fireburyingpoint.emit("top_recharge")
  router.push('/DepositPIX')
}
const goToWithdraw = () => {
  showDetail.value = false
  router.push('/WithdrawalPIX')
}



const goToActivityCenter = () => {
  showDetail.value = false
  router.push('/ActivityCenter')
}

const onrefreshbalance = async () => {
  try {
    refreshbalance.value = true
    await userstore.wallet_get_balance_underScore_actions()
    setTimeout(() => {
      refreshbalance.value = false
    }, 500)
  }
  catch (err) {
    console.log(err)
    refreshbalance.value = false
  }
}

const handledownload = () => {
  router.push('/DownloadMerge')
  //bus.fireappdownload.emit()
}
const close = () => {
  userstore.rewardconfig.isvaild = false
}

const handleClick = () => {
  showDetail.value = !showDetail.value
}

const goToXfunGame = () => {
  showDetail.value = false
  router.push({
    name: 'Mtype', query: {
      mid: 2,
      name: 'Jogo Bônus'
    }
  })
}

const handleHelp = () => {
  router.push('/MoneyHelp')
}

</script>
<template>
  <v-app-bar :height="userstore.canShowDownload ? 110 : 60" app class="custom-app-bar">
    <div class=" d-flex flex-column" style="width: 100%;height: 100%;">
      <div v-if="userstore.canShowDownload" class="d-flex" style="width: 100%;">
        <v-sheet v-if="userstore.canShowDownload" class="d-flex download-sheet justify-space-between"
          style="width: 100%;">
          <div class="d-flex align-center pl-0 py-0">
            <!-- <v-img :src="download" width="45px" height="45px" class="rounded-lg"/> -->
            <img :src="download" width="45px" height="45px" class="rounded-lg" />
            <div class="d-flex flex-column ml-2 pr-2" style="font-size: 9px">
              <div class="download-num ">
                <div class="reward-text" style="font-size: 13px;color: #ffffff;">Xfun APP</div>
                <div class="reward-text">Baixe o APP e ganhe um bônus</div>
              </div>
              <div class="reward-text">
                aleatório de <span style="color: #F9F36F; font-weight: 900;">R${{ userstore.rewardconfig.rewardAmount
                  }}</span>
              </div>
            </div>
          </div>
          <div class="d-flex align-center flex-row">
            <v-icon style="color:#A6A6BD" icon="mdi-close" class="pa-1 mb-1 icon-custom" @click="close"></v-icon>
            <v-btn class="download-btn mr-4" @click="handledownload">
              <p class="btn-text font-weight-bold">Baixar</p>
            </v-btn>
          </div>
        </v-sheet>
      </div>
      <div class="d-flex px-3 py-1 align-center" style="height: 60px;">
        <img src="/menu.png" class="mr-2" style="width:32px;height: 22px;" @click="handleMenu" />
        <div v-if="userstore.isLogin" class="d-flex align-center justify-start">
          <img v-if="userstore.isLogin" style="width:90px;height: 41px;" :src="logo" @click="handleIconClick" />
        </div>
        <div v-else class=" d-flex flex-column align-center">
          <img style="width:80px;height: 40px;" :src="logo" @click="handleIconClick" />
        </div>
        <div v-if="userstore.isLogin" class="d-flex align-center justify-end" style="flex: 1;">
          <ClientOnly>
            <div class="flex items-center text-[11px] gap-[4px] shrink-0">
              <div
                class="h-[40px] min-w-[130px] pl-[6px] pr-[4px] py-[4px] flex shrink-0 items-center gap-[8px] font-bold border-radius-0 bg-color-1">
                <div class="flex items-center justify-between flex-1 shrink-0 gap-[6px]">
                  <img class="w-[20px] h-[20px] shrink-0" :src="moneyIcon"/>
                  <div class="flex items-center gap-x-[8px]">
                    <div class="text-right"><span class="whitespace-pre text-color-text-0 text-[13px]">{{userstore.getUserBalance()}}</span>
                      <div class="text-[12px] text-color-text-1 text-right"><span
                          class="whitespace-pre font-[400]">{{userstore.getUserBalanceGift()}}</span></div>
                    </div>
                    <v-icon :class="showDetail ? 'down' : 'up'" @click="handleClick" icon="mdi-chevron-down"></v-icon>
                  </div>
                </div>
                <div @click="goToDesposit"
                  class="relative h-[32px] w-[32px] flex shrink-0 items-center justify-center bg-color-high-2 border-radius-0">
                  <v-icon icon="mdi-plus-thick" class="text-color-btn-4"></v-icon>
                </div>
              </div>
              <div class="w-[40px] h-[40px] flex items-center justify-center relative" @click="goToBonus">
                
                <img :src="giftImg" class="w-[26px] h-[26px]"/>
               
              </div>
            </div>
          </ClientOnly>
        </div>
        <div v-else class="d-flex align-center justify-end" style="flex: 1;">
          <div class="d-flex ">
            <!-- <XButton style="width: 90px;" size="small" class="mr-3" skin="weak-1" @click="handleTest">
              <text style="color:#A6A6BD">{{ testLabel }}</text>
            </XButton> -->
            <XButton style="width: 90px;" size="small" class="mr-3" skin="weak-1" @click="handleLogin">
              <text style="color:#A6A6BD">{{ "Entrar" }}</text>
            </XButton>
            <XButton style="width: 108px;" size="small" skin="strong" @click="handleRegister">
              {{ "Inscrever-se" }}
            </XButton>
          </div>
        </div>
        <v-expand-transition>
          <div v-if="showDetail" class="detail-box" :style="{ top: userstore.canShowDownload ? '110px' : '60px' }">
            <div class="money-content">
              <div class="money">
                <span class="title">Dinheiro</span>
                <div class="amount">R$ {{ userstore.getUserBalance() }}</div>
                <div v-if="+userstore.getUserBalance() == 0" class="blue-text" @click="goToDesposit">Deposite agora
                </div>
                <div v-if="+userstore.getUserBalance() != 0 && +userstore.getUserRemainingBalanceAudit() != 0"
                  class="green-text">Aposta R${{ userstore.getUserRemainingBalanceAudit() }} retirar
                </div>
                <div v-if="+userstore.getUserBalance() != 0 && +userstore.getUserRemainingBalanceAudit() == 0"
                  class="blue-text" @click="goToWithdraw">Retire agora</div>
              </div>
              <v-divider :thickness="2" class="custom-divider"></v-divider>
              <div class="reward">
                <div class="d-flex justify-space-between align-center">
                  <span class="title">Bônus</span>
                  <!-- <v-img @click="handleHelp" width="20" class="mt-1" inline :src="helpImg" /> -->
                  <img width="20" class="mt-1" style="display: inline;" :src="helpImg" @click="handleHelp" />
                </div>
                <div class="amount">R$ {{ userstore.getUserBalanceGift() }}</div>
                <div v-if="+userstore.getUserBalanceGift() == 0" class="blue-text" @click="goToActivityCenter">
                  Mais bônus</div>
                <div v-if="+userstore.getUserBalanceGift() != 0 && +userstore.getUserRemainingGiftAudit() != 0"
                  class="green-text">Aposta R${{ userstore.getUserRemainingGiftAudit() }} retirar</div>
                <div v-if="+userstore.getUserBalanceGift() != 0 && +userstore.getUserRemainingGiftAudit() == 0"
                  class="blue-text" @click="goToWithdraw">Retire agora</div>
              </div>
            </div>
            <!-- <v-img :src="rewardImg" width="122px" height="160" inline @click="goToXfunGame"/> -->
            <img :src="rewardImg" width="122px" height="160" style="display: inline;" @click="goToXfunGame" />
          </div>
        </v-expand-transition>
        <div v-if="showDetail" class="mask" @click="showDetail = false"></div>
      </div>
    </div>
  </v-app-bar>
</template>
<style scoped lang="scss">
.rotate {
  animation: rotate 300ms linear infinite;
  -webkit-animation: rotate 300ms linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes rotate {
  0% {
    transform: rotate(0);
  }

  25% {
    transform: rotate(90deg);
  }

  50% {
    transform: rotate(180deg);
  }

  75% {
    transform: rotate(270deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.custom-app-bar {
  width: 100%;
  box-shadow: 0px 1px 10px 0px #000000 !important;
  overflow: visible;
  position: fixed;
  z-index: 1000;
  background: linear-gradient(90deg, #1F2239 0.37%, #292C46 60.85%, #2A3762 98.8%);
}

.v-toolbar {
  border-bottom: 1px solid #2D2F4D !important;
  background: linear-gradient(91deg, #1F2239 0.37%, #292C46 60.85%, #2A3762 98.8%) !important;
}

.button-lg-text {
  font-size: 13px;
  font-weight: 700 !important;
}



.content-class {
  background-color: #F59A23;
  position: absolute;
  left: 20%;
  top: -10px;
  z-index: 1500;
  min-width: 60px;
  text-align: center;
  font-size: 13px !important;
  font-weight: 400 !important;
  font-family: ARIAL !important;
  line-height: normal;
  padding: 1px 0;
}

.download-sheet {
  background: linear-gradient(90deg, #3F3678 16.46%, #282E50 100%);
  padding: 2px;
}

.download-btn {
  width: 102px !important;
  height: 35px !important;
  flex-shrink: 0;
  font-size: 22px;
  color: #ffffff;
  border-radius: 15px;
  background: linear-gradient(90deg, #EBD147 1.86%, #C29344 27.9%, #BA8832 79.45%, #D0B04B 99.86%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.10);
}

.download-num {
  font-family: Arial;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

}

.btn-text {
  color: #ffffff;
  font-family: Microsoft YaHei Bold;
  font-size: 13px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.reward-text {
  font-size: 13px;
  color: #A6A6BD;
  font-weight: 400;
  white-space: nowrap;
}

.icon-custom {
  position: absolute;
  top: 0px;
  right: 0px;
}

.deposit-btn {
  :deep(.smallstyle_weak-1) {

    .btn-box,
    .btn-content {
      flex: 1
    }
  }
}

.detail-box {
  position: fixed;
  left: 0;
  right: 0;
  top: 60px;
  border-radius: 0px 0px 15px 15px;
  background: rgba(30, 30, 42, 0.96);
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 4px;
  height: 166px;
  z-index: 90;

  .money-content {
    flex: 1;

  }

  .money,
  .reward {
    padding-left: 4px;
    height: 80px;

    .title {
      font-size: 12px;
      line-height: normal;
      margin-bottom: 4px;
    }

    .amount {
      color: #FFD03A;
      font-size: 17px;
      font-weight: 700;
      line-height: normal;
      margin-bottom: 8px;
      text-align: center;
    }

    .blue-text {
      color: #7669F5;
      font-size: 15px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }

    .green-text {
      color: #0EAE70;
      font-size: 15px;
      font-weight: 700;
      line-height: normal;
      text-align: center;
    }
  }
}

.mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 89;
}

.custom-divider {
  width: calc(100% - 10px);
  transform: translateX(5px);
}

.down {
  transform: rotate(180deg);
  transition: all 0.3s ease-in-out;
}

.up {
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}
</style>
