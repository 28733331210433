<template>
  <v-navigation-drawer v-model="visible" temporary @update:modelValue="onvisiblechange" border="false" :style="{
    'min-width': visible ? '80%' : '0px',
    height: '100%',
  }">
    <div class="d-flex flex-column" style="height: 100%;">
      <div class="px-1 d-flex justify-space-between align-center"
        style="height:60px;min-height: 60px;background: #292E55;">
        <div style="width:90px;" class="ml-3">
          <img :src="logo" style="display: inline;" />
        </div>
        <v-icon icon="mdi-close" color="#A6A6BD" @click="onclose"></v-icon>
      </div>
      <div class="d-flex mx-3 mt-2 topbtnContent" style="height: 35px;" v-if="menus.length > 1">
        <div class="d-flex" style="flex:1" v-for="(item, index) in menus" :key="index">
          <div :class="currentIndex == index ? 'topbtn' : 'topbtn1'" class=" d-flex align-center justify-center"
            @click="handleSelect(index)" style="height: 33px;flex:1;position: relative;">
            <img :src="item.menuIcon" style="max-width:18px;max-height: 18px;" class="mr-2" />
            {{ item.menuName }}
            <div :class="currentIndex == index ? 'topbtnselected' : ''" />
          </div>
        </div>
      </div>

      <div v-for="itemMenu, index in menus" id="scrollviewNa" :key="index" class="mx-3" :style="{
        'min-height': index == currentIndex ? '85%' : '0px',
        'max-height': index == currentIndex ? '80%' : '0px',
        'overflow': 'scroll',
        'overscroll-behavior': 'none'
      }">
        <v-list v-show="index == currentIndex" id="contentNa" density="compact" style="width:100%;"
          v-model:opened="open">
          <template v-slot:item>
            {{ props }}
          </template>
          <NDListItem v-for="(itemMenudata, index) in itemMenu.menus" :key="index" :item="itemMenudata"
            :redirect_callback="onredirect" :currentSelect="currentSelect" />


        </v-list>
      </div>

    </div>

  </v-navigation-drawer>
</template>


<script setup lang="ts">
import NDListItem from "./NDListItem.vue"
import logo from '../../assets/components/appbar/logo.png'
import { useBus } from '@/composables/xfun/bus'

const bus = useBus()
const props = defineProps(['modelValue'])
const emit = defineEmits(['update:modelValue', 'redirect'])

const currentIndex = ref(0)
const drawStyle = computed(() => visible ? 'min-width: 80%;height: 100%;' : 'height: 100%;')

const handleSelect = (index: any) => {
  if (menus[index].redirectId) {
    let rt=createredirectinfo(menus[index].redirectId,menus[index].redirectContent,menus[index].extraParams)
    bus.fireDoMenuItem.emit(rt)
    return
  }
  currentIndex.value = index
}

let visible = false
visible = props.modelValue

watch(() => props.modelValue, (v) => {
  visible = v
  if (visible) bindfun(visible)
})

const onvisiblechange = (v: boolean) => {

  emit('update:modelValue', v)
}

const onclose = () => {
  emit('update:modelValue', false)
}


const currentSelect = ref(-1)

const onredirect = (item: any, id: number) => {
  const userdata = createredirectinfo(item.redirectId, item.redirectContent, item.extraParams)
  if (userdata) {
    currentSelect.value = id
    emit('redirect', userdata)
  }
  emit('update:modelValue', false)
}

const store = useUserStore()


const _menus: any = store.menuInfo
const menus: any = _menus.menuPageList
const onecol = menus[0].menus;
const open: any = ref([])

onecol.forEach((item: any) => {
  if (item.type == 3) {
    open.value.push(item.title.value)
  }
})

onMounted(() => {
  let overlayDom: any = document.getElementsByClassName('v-navigation-drawer')
  if (overlayDom && overlayDom.length > 0) {
    overlayDom = overlayDom[0]
    overlayDom.addEventListener('touchmove', touchFun, { passive: false });
    overlayDom.addEventListener('wheel', wheelFun, { passive: false });
  }
})
const touchFun = (e: TouchEvent) => {
  let scrollviewNa: any = document.getElementById('scrollviewNa')
  let contentNa: any = document.getElementById('contentNa')

  if (contentNa.clientHeight <= scrollviewNa.clientHeight) {
    e.preventDefault()
  }
}

const wheelFun = (e: WheelEvent) => {

  let scrollviewNa: any = document.getElementById('scrollviewNa')
  let contentNa: any = document.getElementById('contentNa')

  if (contentNa.clientHeight <= scrollviewNa.clientHeight) {
    e.preventDefault()
  }
}

const bindfun = (visible: boolean) => {
  setTimeout(() => {
    let overlayDom: any = document.getElementsByClassName('v-navigation-drawer__scrim')
    if (overlayDom && overlayDom.length > 0) {
      overlayDom = overlayDom[0]
      overlayDom.style.zIndex = 1500
      overlayDom.addEventListener('touchmove', (e: any) => { e.preventDefault() }, { passive: false });
      overlayDom.addEventListener('wheel', (e: any) => { e.preventDefault() }, { passive: false });
    }
  }, 100);
}

</script>
<style lang="scss" scoped>
:deep(.v-input__control .v-field) {
  background: transparent !important;
  border-radius: 15px;
}

.topbtnContent {
  padding: 2px;
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.25);
  background-color: #191924;
  border-radius: 2px;
}

.topbtn {
  border-radius: 2px 2px 0 0;
  background: linear-gradient(180deg, rgba(47, 40, 120, 0.20) 0%, #112D6A 78%);
  //font-size: 12px ;
}

.topbtn1 {
  border-radius: 2px 2px 0 0;
  color: #A6A6BD //font-size: 12px ;
}

.topbtnselected {
  border-radius: 0px 0px 2px 2px;
  background: #1E5CE0;
  width: 100%;
  height: 2px;
  position: absolute;
  bottom: 0px;
}

:deep(.v-list-group__items .v-list-item) {
  padding-inline-start: 20px !important;
}
</style>