<template>
  <v-app theme="dark" class="min-h-screen custom-bg">
    <HeadBar v-if="isTop" @click-menu="onHandleMenu" @register="onRegister" @login="onLogin" @app-iconclick="goHome" />
    <ClientOnly v-if="isTop">
      <NavigationDrawer style="top: '0px'; z-index: 2000" v-model="isMenuOpen" @redirect="_onredirect"
        @update:modelValue="handleVisableMenu" />
    </ClientOnly>
    <slot />
    <XTabBar v-if="isTop" @change="ontabbarchange" />
  </v-app>
</template>

<script setup lang="ts">
/* Vuetify useTheme will not work with SSR enabled, more info - https://vuetify-nuxt-module.netlify.app/guide/server-side-rendering.html#vuetify-themes */
// import { useCustomTheme } from '~/composables/custom-theme'
/* Update on using the above technique - We now use SSR HTTP Client hints technique instead of the above - https://vuetify-nuxt-module.netlify.app/guide/server-side-rendering.html#ssr-http-client-hints */
// import { RedirectType } from "#imports"
import HeadBar from '~/my-nuxt-vuetify-tailwindcss-layer/components/nav/HeadBar.vue'
import XTabBar from '~/components/lib/xtabbar.vue'
import { useBus } from '@/composables/xfun/bus'
import { postMessage } from '~/utils'
import { PageTypeId } from '../types/xfun';
import 'virtual:svg-icons-register'
const router = useRouter()
const route = useRoute()
const bus = useBus()
const { t } = useI18n()

const commonStore = useCommonStore()
const userStore = useUserStore()

// 一级路由
const topRoutes = ['index', 'DepositPIX','DepositUSDT', 'Promotion', 'ActivityCenter', 'UserInfo', 'category-id', 'index-slotsDaily', 'index-slots', 'index-shortcut','Betby','index-checkIn','BonusCenter']
const isTop = computed(() => {
  // console.log('[Route]--------->', route.fullPath)
  return topRoutes.findIndex(item => item == route.name) > -1
})

const isMenuOpen = ref(false)

const ontabbarchange = (index: number) => {
  console.log('APP Tabbar ontabbarchange', index)
  if (index == 0) {
    router.push('/')
  } else if (index == 1) {
    bus.fireburyingpoint.emit("bottom_active")
    router.push('/ActivityCenter')
  } else if (index == 2) {
    bus.fireburyingpoint.emit("bottom_invite")
    router.push('/Promotion')
  } else if (index == 3) {
    bus.fireburyingpoint.emit("bottom_recharge")
    router.push('/DepositPIX')
  } else if (index == 4) {
    bus.fireburyingpoint.emit("bottom_personal")
    router.push('/UserInfo')
  }
}

const onHandleMenu = () => {
  console.log('APP onHandleMenu')
  isMenuOpen.value = !isMenuOpen.value
}

let ActivityNotOpened: any = computed(() =>
  t('$vuetify.TopTips.ActivityNotOpened'),
)


onMounted(async () => {
  console.log('===> onMounted HomeView')
  bus.firelogin.reset()
  bus.firereg.reset()
  bus.fireroulette.reset()
  bus.fireslots.reset()
  bus.fireslotsDaily.reset()
  bus.firegohome.reset()
  bus.fireappdownload.reset()
  bus.fireMenu.reset()

  bus.firelogin.on(onLogin)
  bus.firereg.on(onRegister)
  bus.fireroulette.on(openRoulette)
  bus.fireslots.on(onSlots)
  bus.fireslotsDaily.on(onSlotsDaily)
  bus.firegohome.on(fgohome)
  bus.fireappdownload.on(onappdownload)
  bus.fireMenu.on(onHandleMenu)
  bus.fireLangChange.emit()

  // 处理启动后的跳转
  console.log('Current specified routing information route', route)
  if (route.name == 'register') {
    bus.firereg.emit(route.query.toString())
  } else if (route.name == 'login') {
    bus.firelogin.emit()
  } else if (route.name == 'roulette') {
    bus.fireroulette.emit()
  }

  if (route.name == 'games') {
    //appbarVisable.value = false
  }

  setTimeout(() => {
    initlivechat()
  }, 5000)
  // init()
})

/** 生命周期 */
onActivated(async () => {
  console.log('===> onActivated HomeView')
})

const startShortcuts = () => {
  router.push('/shortcut')
}

const _onredirect = async (item: any) => {
  onredirect(item)
}

const maintainvisible = ref(false)
bus.fireMaintainDialog.on((v: any) => {
  maintainvisible.value = true
})
const closeMaintainInfoPopup = () => {
  maintainvisible.value = false
}
const handleClose = () => {
  console.log('handleClose')
}

var _window: any = window

/** 侧滑菜单事件处理 */
const onredirect = async (item: any) => {
  let type: number = item.type
  if (type == RedirectType.InnerPage && item.id == PageTypeId.recharge) {
    bus.fireburyingpoint.emit('slide_recharge')
  }

  if (
    type == RedirectType.InnerPage &&
    item.id == PageTypeId.transferaccounts
  ) {
    bus.fireburyingpoint.emit('slide_withdraw')
  }

  if (type == RedirectType.Function && item.id == FunctionTypeId.LiveChat) {
    bus.fireburyingpoint.emit('slide_service')
  }

  console.log('redirect:', item)
  let rt: RedirectInfo = item
  type = item.type

  // 内部页面跳转
  if (type == RedirectType.InnerPage) {
    
    const pageId = item.id
    if (pageId == PageTypeId.recharge) {
      router.push('/depositPIX') //直接跳转到充值页
    } else if (pageId == PageTypeId.transferaccounts) {
      router.push('/withdrawalPIX')
    } else if (pageId == PageTypeId.vip) {
      router.push('/VipNew')
    } else if (pageId == PageTypeId.gameclassify) {
      let param = rt.param
      if (param) {
        router.push(`/type/${param}`)
      } else {
        router.push(`/type`)
      }
    } else if (pageId == PageTypeId.message) {
      router.push(`/message`)
    } else if (pageId == PageTypeId.RedemptionCode) {
      router.push(`/Redemption`)
    } else if (pageId == PageTypeId.bonusRecord) {
      router.push(`/bonusRecord`)
    } else if (pageId == PageTypeId.customclassify) {
      let param = rt.param
      if (param) {
        router.push(`/category/${param}`)
      } else {
        router.push(`/category`)
      }
    } else if (pageId == PageTypeId.accountprivacy) {
      router.push(`/account`)
    } else if (pageId == PageTypeId.gameproviderlist || pageId == PageTypeId.providerclassify) {
      let param = rt.param
      if (param) {
        router.push(`/providers?mir=${param.mir}&name=${param.name}`)
      }
    } else if (pageId == PageTypeId.install) {
      router.push('/DownloadMerge')
    } else if (pageId == PageTypeId.mtype) {
      let param = rt.param
      router.push(`/Mtype?mid=${param.mid}&name=${param.name}`)
    } else if (pageId == PageTypeId.mproviders) {
      let param = rt.param
      if (param.defaultRow == null) param.defaultRow = 1
      router.push(`/MProviders?mir=${param.mid}&name=${param.name}&defaultRow=${param.defaultRow}`)
    } else if (pageId == PageTypeId.mcategory) {
      let param = rt.param
      router.push(`/Mcategory?mid=${param.mid}&mir=${param.tid}&name=${param.name}`)
    } else if (pageId == PageTypeId.checkIn) {
      router.push('/checkIn')
    } else if (pageId == PageTypeId.RedActivity) {
      router.push('/RedActivity')
    }else if (pageId == PageTypeId.VipRebate) {
      router.push('/VipCashback')
    }else if (pageId == PageTypeId.BonusCenter) {
      router.push('/BonusCenter')
    }else if (pageId == PageTypeId.ActivityCenter) {
      router.push('/ActivityCenter')
    }
    else if (pageId == PageTypeId.home) {
      router.push('/')
    }else if (pageId == PageTypeId.login) {
      router.push('/login')
    }else if (pageId == PageTypeId.register) {
      router.push('/register')
    }else if (pageId == PageTypeId.userinfo) {
      router.push('/UserInfo')
    }else if (pageId == PageTypeId.betHistory) {
      router.push('/BetsHistory')
    }else if (pageId == PageTypeId.ContinuousRecharge) {
      router.push('/ContinuousRecharge')
    }else if (pageId == PageTypeId.FirstChargePage) {
      router.push('/FirstChargePage')
    }else if (pageId == PageTypeId.collect) {
      router.push('/type/-1')
    }else if (pageId == PageTypeId.history) {
      router.push('/type/-2')
    }
    return
  }

  if (type == RedirectType.OutterOpenNewWebView) {
    const url = item.url
    setTimeout(() => {
      openWebview(url)
    }, 0)
  }



  // 游戏跳转
  if (type == RedirectType.Game) {
    let merId = item.merId
    let gameId = item.gameId
    let bet = item.bet
    let ischampion = item.ischampion || false
    if (!userStore.isLogin) {
      router.push('/login')
    } else {
      try {
        let res: any = await userStore.game_gameLogin_actions({
          merId,
          gameId,
        })

        if (res.data.token != null) {
          userStore.betbyruninfo.brandId = res.data.merchantNo
          userStore.betbyruninfo.token = res.data.token

          router.push('/betby' + `${bet}`)
        } else {
          userStore.gameruninfo.url = res.data.gameUrl
          router.push({
            path: '/RunGame',
            query: {
              gameid: gameId,
              mid: merId,
              ischampion: ischampion,
              gamename: res.data.gameName,
              type: res.data.urlType,
            },
          })
        }
      } catch (error: any) {
        console.log(error)
        if (error.isMaintain) {
          bus.fireMaintainDialog.emit()
        }
      }
    }
    return
  }

  // 活动跳转
  if (type == RedirectType.Activity) {
    const activityId = item.id
    if (activityId == ActivityTypeId.promotion) {
      router.push('/promotion')
    } else if (activityId == ActivityTypeId.firstcharge) {
      router.push('/firstCharge')
    } else if (activityId == ActivityTypeId.daily) {
      router.push('/dailyTasks')
    } else if (activityId == ActivityTypeId.turntable) {
      router.push('/roulette')
    } else if (activityId == ActivityTypeId.lotterydraw) {
      router.push('/lotteryDraw')
    } else if (activityId == ActivityTypeId.kickball) {
      router.push('/redPacket')
    } else if (activityId == ActivityTypeId.weeklyList) {
      router.push('/weeklyList')
    } else if (activityId == ActivityTypeId.weeklyCard) {
      router.push('/weeklyCard')
    } else if (activityId == ActivityTypeId.redemptioncode) {
      router.push('/Redemption')
    } else if (activityId == ActivityTypeId.SharingRebatesView) {
      router.push('/sharingRebates')
    } else if (activityId == ActivityTypeId.RedRain) {
      bus.fireredrain.emit()
    } else if (activityId == ActivityTypeId.Championship) {
      router.push('/championship')
    }
    return
  }

  // 功能调转
  if (type == RedirectType.Function) {
    const funcId = item.id
    if (funcId == FunctionTypeId.Install) {
      if (isMobile()) {
        var u = navigator.userAgent
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //Android
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //iOS

        var res
        if (isAndroid) {
          res = commonStore.apkDownloadInfo.apkUrl // "https://down.xfun.app/apk/app-SlotsCartoon-release717-2.apk"
        } else {
          res = commonStore.apkDownloadInfo.iosUrl // "https://apps.apple.com/cn/app/id1634658921/id1634658921"
        }

        var url = res

        setTimeout(() => {
          openWebview(url)
        }, 0)

        // 应用安装功能
        postMessage('deskdl')
      } else {
        if (_window.saveToHome) {
          // pc版本下提示安装
          _window.saveToHome()
          return
        }
      }
    } else if (funcId == FunctionTypeId.PWAInstall) {

      if (isAndroid()) {
        if (_window.saveToHome) {
          bus.fireopenPWAdialog.emit()
        }
       } else {
         startShortcuts()
       }
    } else if (funcId == FunctionTypeId.LiveChat) {
      livechatcall('maximize')
    } else if (funcId == FunctionTypeId.Shortcuts) {
      startShortcuts()
    }
    return
  }
}

bus.fireDoMenuItem.reset()
bus.fireDoMenuItem.on(onredirect)

/** appbar事件处理 */
const visible = ref(false)

const onappIconclick = () => {
  router.push('/')
}
bus.fireMenu.on(() => {
  visible.value = !visible.value
})



/** 页面的事件处理 */
const onLogin = () => {
  router.push('/login')
}
const goHome = () => {
  router.push('/')
}
const onRegister = (query: any) => {
  if (query && Object.keys(query).length > 0) {
    router.push({ name: 'register', query: query })
    return
  }
  router.push('/register')
}

const openRoulette = () => {
  router.push('/roulette')
}

const onSlots = () => {
  router.push('/slots')
}

const onSlotsDaily = () => {
  router.push('/slotsDaily')
}

const fgohome = () => {
  console.log('>>>> fgohome')
  if (router.currentRoute.value.matched.length > 0) {
    router.replace('/')
  }
}

const onappdownload = () => {
  onredirect({
    type: RedirectType.Function,
    id: FunctionTypeId.Install
  })
}

const handleVisableMenu = (v: boolean) => {
  bus.fireToPreMenuItem.emit()
}

const handleMenu = () => {
  // 侧滑菜单
  bus.fireMenu.emit()
}

</script>

<style scoped>
.custom-bg {
  background: transparent !important;
}
</style>
