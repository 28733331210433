<template>
  <v-list-group
    class="mx-0 mb-1"
    :value="getTitle(itemstate.title.value)"
    v-if="hasChild"
    style="border-radius: 30px"
  >
    <template
      v-slot:activator="{ props, isOpen }"
      v-if="itemstate.params && itemstate.params.childstyle != 'fast' && itemstate.params.childstyle != 'importance'"
    >
      <v-list-item
        v-bind="props"
        :style="{
          'border-radius': '30px',
          'font-size': '12px',
          background: isOpen ? 'transparent !important' : 'rgba(255, 255, 255, 0.10) !important'
        }"
        v-ripple="false"
        :height="34"
        class="group"
      >
        <template v-slot:prepend v-if="itemstate.icon.value">
          <img :src="itemstate.icon.value" style="width: 21px; height: 21px" class="mr-2" />
          <div :style="{
            'font-weight': isOpen ? 'bolder !important' : 'normal !important',
            'font-size': isOpen ? '13px' : '12px'
            }">{{ getTitle(itemstate.title.value) }}</div>  
        </template>
        
      </v-list-item>
    </template>

    <div class="grid3" v-if="itemstate.params && itemstate.params.childstyle == 'fast'">
      <div v-for="(child, i) in itemstate.childs" :key="i" :item="child" class="ml-0 my-0 d-flex">
        <NDListItem
          :parentinfo="itemstate.params ? itemstate.params.childstyle : null"
          class="grid3item2"
          style="width: 100%"
          :morelevel="true"
          :item="child"
          :redirect_callback="props.redirect_callback"
          :currentSelect="props.currentSelect"
        ></NDListItem>
      </div>
    </div>

    <div class="grid2" v-else-if="itemstate.params && itemstate.params.childstyle == 'importance'">
      <div v-for="(child, i) in itemstate.childs" :key="i" :item="child" class="ml-0 my-0 d-flex">
        <NDListItem
          :parentinfo="itemstate.params ? itemstate.params.childstyle : null"
          class="grid3item"
          style="width: 100%"
          :morelevel="true"
          :item="child"
          :redirect_callback="props.redirect_callback"
          :currentSelect="props.currentSelect"
        ></NDListItem>
      </div>
    </div>

    <div
      class="grid2game"
      v-else-if="
        itemstate.params && 
        (itemstate.params.childstyle == 'col2game' ||
        itemstate.params.childstyle == 'Gamemanufacturer')
      "
    >
      <div v-for="(child, i) in itemstate.childs" :key="i" :item="child" class="ml-0 mt-1 d-flex">
        <NDListItem
          :parentinfo="itemstate.params ? itemstate.params.childstyle : null"
          class="grid3item"
          style="width: 100%"
          :morelevel="true"
          :item="child"
          :redirect_callback="props.redirect_callback"
          :currentSelect="props.currentSelect"
        ></NDListItem>
      </div>
    </div>

    <div
      v-else
      v-for="(child, i) in itemstate.childs"
      :key="i"
      :item="child"
      class="ml-0 mt-1 d-flex"
    >
      <NDListItem
        :parentinfo="itemstate.params ? itemstate.params.childstyle : null"
        class="grid3item"
        style="width: 100%"
        :morelevel="true"
        :item="child"
        :redirect_callback="props.redirect_callback"
      ></NDListItem>
    </div>
  </v-list-group>

  <v-list-item
    v-ripple="true"
    v-else-if="itemstate.itemstyle == 'normal' && itemstate.title.value != '--'"
    :class="props.morelevel ? '' : 'my-1 mx-0'"
    :style="{
      margin: props.morelevel ? '2px 0px' : '2px 0px',
      'border-radius':  '5px !important',
      background: props.morelevel ? 'rgba(255, 255, 255, 0.04)' : 'rgba(255, 255, 255, 0.10)   ',
      color: props.morelevel ? '#A6A6BD' : '',
      minHeight:'30px !important',
    }"
    :height="isNormal ? 'auto' : 35"
    @click="props.redirect_callback(JSON.parse(JSON.stringify(itemstate)), itemstate.id)"
  >
    <template v-slot:default>
        
      <template v-if="props.parentinfo == 'fast'">
        <div class="grid3item2">
          <img :src="itemstate.icon.value" style="width: 21px" />
        </div>
        <div :style="{
            'font-weight': isSelected(props.currentSelect, itemstate.id) ? 'bolder !important' : 'normal !important',
            'font-size': '10px',
            color: isSelected(props.currentSelect, itemstate.id) ? '#FFFFFF' : '#A6A6BD',
            lineHeight: 1.2
        }">{{ getTitle(itemstate.title.value) }}</div>
      </template>

      <template v-else-if="props.parentinfo == 'importance'">
        <div class="grid2item">
          <img :src="itemstate.icon.value" />
        </div>
        <div
          class="grid2itemtext"
          style="font-size: 12px; left: 60%; top: 50%; transform: translateY(-50%)"
        >
          <div>{{ getTitle(itemstate.title.value) }}</div>
        </div>
      </template>

      <template v-else-if="props.parentinfo == 'col2game'">
        <div class="grid3item">
          <img :src="itemstate.icon.value" style="width: 21px; height: 21px;" />
          <div :style="{
            'font-weight': isSelected(props.currentSelect, itemstate.id) ? 'bolder !important' : 'normal !important',
            'font-size': '10px',
            color: isSelected(props.currentSelect, itemstate.id) ? '#FFFFFF' : '#A6A6BD',
        }" style="margin-left: 5px;">{{ getTitle(itemstate.title.value) }}</div>
        </div>
      </template>
      <template v-else-if="props.parentinfo == 'Gamemanufacturer'">
        <div class="grid22item">
          <img :src="itemstate.icon.value" />
        </div>
      </template>

      <template v-else>
        <div class="grid3item">
          <img
            :src="itemstate.icon.value"
            style="width: 21px; height: 21px; "
          />
          <div style="font-size: 12px;margin-left: 10px;">{{ getTitle(itemstate.title.value) }}</div>
        </div>
      </template>
    </template>
  </v-list-item>
  <div v-ripple="false" v-else-if="itemstate.title.value == '--'" class="my-4"></div>
  <div
    v-else
    style="height: 45px"
    class="my-0"
  >
    <img
      :src="itemstate.icon.value"
      @click="props.redirect_callback(JSON.parse(JSON.stringify(itemstate)))"
      style="object-fit: cover; width: 100%; height: 100%; max-height: 45px; border-radius: 8px"
    />
  </div>
</template>

<script setup lang="ts">
import { watch, ref, reactive, onMounted, computed } from 'vue'

const store = useUserStore()

const isNormal = computed(() => {
  return (
    props.parentinfo != 'col2game' && props.parentinfo != 'fast' && props.parentinfo != 'importance'
  )
})

const props = defineProps({
  item: {
    type: Object,
    required: false,
    default: () => {
      return {
        type: MenuItemType.List,
        title: {
          value: 'AAA'
        },
        icon: {
          type: IconType.Fonticon,
          value: 'mdi-account-circle'
        },
        childs: [
          {
            type: MenuItemType.List,
            title: {
              value: 'aaa'
            },
            icon: {
              type: IconType.Fonticon,
              value: 'mdi-account-circle'
            }
          },
          {
            type: MenuItemType.List,
            title: {
              value: 'bbb'
            },
            icon: {
              type: IconType.Fonticon,
              value: 'mdi-account-circle'
            }
          }
        ]
      }
    }
  },
  redirect_callback: {
    type: Function,
    required: false,
    default: () => {}
  },
  morelevel: {
    type: Boolean,
    required: false,
    default: false
  },
  parentinfo: {
    type: String
  },
  currentSelect: {
    type: Number
  }
})

const itemstate = reactive(props.item)
if (itemstate.childs == null) {
  console.error('itemstate.childs is null', itemstate)
}
const hasChild = ref(itemstate.childs.length > 0)
const getTitle = (title: string) => {
  return title
}

const isSelected = (selectItemid:any, currentid:any) => {
  return  selectItemid == currentid
}

</script>

<style lang="scss" scoped>
.group {
  border-radius: 5px !important;
}

.group:first-child {
  //border: 1px solid #515363 !important;
}

.grid3 {
  display: grid;
  grid-template-columns: 30% 33.33% 33.33%;
  grid-template-rows: 33.33% 33.33% 33.33%;
  grid-column-gap: 5px;
  justify-content: center;
}
.grid2 {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 5px;
  justify-content: center;
}
.grid2game {
  display: grid;
  grid-template-columns: 48% 48%;
  grid-column-gap: 5px;
  justify-content: center;
}

.grid3item {
  display: flex;
  justify-content: left;
  align-items: center;
}

.grid3item2 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grid2item {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    max-height: 45px;
    border-radius: 16px;
  }
}

.grid22item {
  display: flex;
  align-items: center;
  margin: 1px 0;
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.grid2itemtext {
  position: absolute;
}
.v-list-item--density-compact.v-list-item--one-line {
  min-height: 0px !important;
}

</style>
